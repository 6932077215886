module.exports = {
  couple: 'couple',
  therapist: 'Therapist',
  room: 'Room',
  paidOn: 'Paid on',
  unpaid: 'Unpaid',
  hasBeen: 'Has been',
  locked: 'locked',
  archived: 'archived',
  shifted: 'shifted | and shifted',
  hasNote: 'Has a note',
  hasPatientNote: 'Has a patient note',
  hasSecretNote: 'Has a secret note',
  noP24Config:
    'The registration procedure for Przelewy24 has not been completed.',
  paymentNotRequired: 'Payment not required',
  deletedTherapist:
    "This Employee has been deleted or no longer has the required role: 'Employee'."
}
