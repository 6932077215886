module.exports = {
  title: 'E-mail',
  emailSettings: 'Ustawienia e-mail',
  emailSettingsDescription:
    'Określ kiedy i jak pacjenci będą otrzymywali powiadomienia e-mail',
  comingSoon:
    'Pracujemy nad nową funkcją! Już wkrótce w tym miejscu wyślesz maile do pacjentów.',
  tabs: {
    messages: 'Treść',
    settings: 'Ustawienia'
  },
  messages: {
    title: 'Treść wiadomości',
    subtitle: 'Określ treść wiadomości jakie pacjenci będą otrzymać od Ciebie',
    forPatients: 'Dla pacjentów',
    forEmployees: 'Dla pracowników',
    options: {
      reminders: 'Przypomnienia',
      newReservation: 'Nowa rezerwacja',
      canceledMeeting: 'Odwołane spotkanie',
      changeDate: 'Zmiana terminu',
      newOnlineReservation: 'Nowa rezerwacja online',
      employeeNewReservation: 'Nowa rezerwacja',
      settings: 'Ustawienia'
    },
    reminders: {
      patientsWillReceive: 'Moi pacjenci otrzymają przypomnienia:',
      title: 'Wyślij do pacjenta przypomnienie e-mail o umówionym spotkaniu',
      subtitle:
        'Po włączeniu system będzie wysyłał przypomnienia email o umówionych spotkaniach.',
      content:
        'Szanowny Pacjencie,\n\nPrzypominamy o wizycie zaplanowanej na {date} o godzinie {time} w gabinecie {practice}.\n\nW razie pytań prosimy o kontakt.\n\nPozdrawiamy,\n{practiceName}',
      timing: {
        fixedAdvance: 'Ze stałym wyprzedzeniem 24h przed spotkaniem',
        fixedTime: 'O stałej porze 1 dzień przed spotkaniem o godz. 8:00',
        fixedAdvanceLabel: 'Ze stałym wyprzedzeniem',
        fixedAdvanceSuffix: 'przed spotkaniem',
        fixedTimeLabel: 'O stałej porze',
        fixedTimeSuffix: 'przed spotkaniem o godz.'
      },
      days: {
        oneDay: '1 dzień',
        twoDays: '2 dni',
        threeDays: '3 dni'
      },
      emailTitle: 'Tytuł wiadomości email',
      emailContent: 'Treść wiadomości email',
      emailTitlePlaceholder: 'Przypomnienie o wizycie',
      emailContentPlaceholder: 'Treść wiadomości email',
      save: 'Zapisz',
      cancel: 'Anuluj'
    },
    newReservation: {
      title: 'Wyślij do pacjenta powiadomienie e-mail o nowym spotkaniu',
      subtitle:
        'Po włączeniu system będzie wysyłał wiadomość email po umówieniu nowego spotkania.',
      emailTitle: 'Tytuł wiadomości email',
      emailContent: 'Treść wiadomości email',
      emailTitlePlaceholder: 'Nowa wizyta',
      emailContentPlaceholder: 'Treść wiadomości email'
    },
    newOnlineReservation: {
      title:
        'Wyślij do pacjenta powiadomienie e-mail o nowej rezerwacji online',
      subtitle:
        'Po włączeniu system będzie wysyłał wiadomość email po dokonaniu rezerwacji online przez pacjenta.',
      emailTitle: 'Tytuł wiadomości email',
      emailContent: 'Treść wiadomości email',
      emailTitlePlaceholder: 'Nowa rezerwacja online',
      emailContentPlaceholder: 'Treść wiadomości email'
    },
    canceledMeeting: {
      title: 'Wyślij do pacjenta powiadomienie e-mail o odwołaniu spotkania',
      subtitle:
        'Po włączeniu system będzie wysyłał wiadomość email po odwołaniu spotkania.',
      emailTitle: 'Tytuł wiadomości email',
      emailContent: 'Treść wiadomości email',
      emailTitlePlaceholder: 'Odwołanie spotkania',
      emailContentPlaceholder: 'Treść wiadomości email',
      defaultTitle: 'Odwołanie spotkania',
      defaultContent:
        'Szanowny Pacjencie,\n\nInformujemy, że Twoje spotkanie w dniu {date} o godzinie {time} w {practiceName} zostało odwołane.\n\nW razie pytań prosimy o kontakt.\n\nPozdrawiamy,\nZespół {practiceName}'
    },
    changeDate: {
      title: 'Wyślij do pacjenta powiadomienie e-mail o zmianie terminu',
      subtitle:
        'Po włączeniu system będzie wysyłał wiadomość email po zmianie terminu spotkania.',
      emailTitle: 'Tytuł wiadomości email',
      emailContent: 'Treść wiadomości email',
      emailTitlePlaceholder: 'Zmiana terminu spotkania',
      emailContentPlaceholder: 'Treść wiadomości email',
      defaultTitle: 'Zmiana terminu spotkania',
      defaultContent:
        'Szanowny Pacjencie,\n\nInformujemy, że termin Twojego spotkania został zmieniony. Nowy termin to {date} o godzinie {time} w {practiceName}.\n\nW razie pytań prosimy o kontakt.\n\nPozdrawiamy,\nZespół {practiceName}'
    },
    employeeSettings: {
      title: 'Ustawienia powiadomień email dla pracowników',
      subtitle:
        'Wybierz, w jaki sposób terapeuci będą otrzymywać powiadomienia o nowych, zmienionych lub odwołanych wizytach w kalendarzu.',
      newVisits: {
        sectionTitle: 'Powiadomienia o nowych wizytach',
        title: 'Powiadomienie email o nowej wizycie',
        description:
          'Pracownik otrzyma powiadomienie email, gdy zostanie dodana nowa wizyta do jego kalendarza.'
      },
      changedVisits: {
        sectionTitle: 'Powiadomienia o zmienionych wizytach',
        title: 'Powiadomienie email o zmienionej wizycie',
        description:
          'Pracownik otrzyma powiadomienie email, gdy wizyta w jego kalendarzu zostanie zmieniona.'
      },
      canceledVisits: {
        sectionTitle: 'Powiadomienia o odwołanych wizytach',
        title: 'Powiadomienie email o odwołanej wizycie',
        description:
          'Pracownik otrzyma powiadomienie email, gdy wizyta w jego kalendarzu zostanie odwołana.'
      }
    },
    common: {
      save: 'Zapisz',
      cancel: 'Anuluj'
    }
  },
  settings: {
    title: 'Ustawienia wysyłki e-mail',
    subtitle: 'Skonfiguruj podstawowe ustawienia wysyłki wiadomości e-mail',
    replyToEmail: {
      label: 'Adres email "Odpowiedz do"',
      description:
        'Ten adres email będzie używany jako adres w funkcji "Odpowiedz do" (reply-to). Domyślnie używany jest adres systemowy.',
      placeholder: 'np. kontakt@twojagabinet.pl'
    },
    senderName: {
      label: 'Nazwa nadawcy',
      description: 'Ta nazwa będzie wyświetlana jako nadawca wiadomości email.',
      placeholder: 'np. Gabinet Lekarski'
    },
    save: 'Zapisz',
    cancel: 'Anuluj'
  },
  description: 'Zarządzaj ustawieniami email'
}
