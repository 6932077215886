module.exports = {
  therapistNotificationsEnabled:
    'Enabled therapist email notifications for this template',
  therapistNotificationsDisabled:
    'Disabled therapist email notifications for this template',
  notifyTherapists: 'Notify therapists via email',
  confirmRestore: 'Are you sure you want to restore the default message',
  redirectToSmsPayment: 'You will be redirected to the payment page',
  smsPacketBought:
    'We received confirmation of payment. SMS has been bought. You will receive an email with confirmation from Przelewy24.',
  packetPurchaseError:
    'Error while purchasing the packet. Please contact Przelewy24 or try again later.',
  smsPacketAwaitingPayment:
    'We are awaiting for your payment. You will receive an email with payment status from Przelewy24.',
  toVerifyMessage:
    'To verify your message just type your phone number and send it to yourself.',
  yourPhoneNumber: 'Your phone number',
  typePhoneNumber: 'Type your phone number…',
  close: '@:common.close',
  save: '@:common.save',
  ifYourMessageContain:
    "If your message contains the meeting date and time in this message it will be set to today's date.",
  errorMessage: 'Please enter a valid phone number (9-18 characters)',
  sendSmsToPatient: 'Send SMS to patient',
  message: 'Message text',
  messageWillBeSentTo: 'Message will be sent to',
  sendTestMsg: 'Send test message',
  smsSend: 'Sms was sent successfully',
  smsSendError: 'Error while sending sms',
  send: 'Send',
  sms: 'Notifications',
  configuration: 'Configuration',
  messages: 'Messages',
  history: 'History',
  smsUsage: 'SMS Usage',
  sendingTimes: 'Sending times',
  newMeeting: 'New meeting reminder',
  smsSenderCurrentNameIs: 'Message Sender',
  smsSender: 'SMS Sender',
  changeSMSSenderNameButtonText: 'Change sender name',
  changeSMSSenderExplanation:
    'You can replace the default sender name that will appear as the SMS sender. The name can have a maximum of 11 characters and cannot include Polish letters.',
  changeSenderSMSHeaderTitle: 'Change SMS sender name',
  changeSMSSenderPricing:
    "Want to use this option? It's available for an additional fee – check our pricing list for more details!",
  clickToRequestNewName: 'Click to specify a new SMS sender name',
  waitingConfirmationTooltipText:
    'Check your email box, fill in the form and send it as soon as possible. We are waiting for your move!',
  changeSenderSMSDialogText:
    'Enter a new SMS sender name below. Remember, it can have a maximum of 11 characters and cannot include Polish letters.',
  changeSenderSMSDialogSubText:
    'The provided name must be associated with your company or brand. After clicking "Save," you will receive an email from us containing a document from SMSApi. Fill out the document and send us its scan. We will forward it to SMSApi, and after their approval, we will activate the new sender field. Only then will you be able to use the new sender name.',
  senderSMSCharsLeft: 'Characters left',
  changeSMSSenderNameRequestSent:
    'The request has been sent. Please check your e-mail',
  senderSMSInputName: 'SMS sender',
  howManySMSShouldBeSentTitle:
    'Sending SMS reminders for each appointment in the series',
  sendSMSOnlyToFirstSessionOrAllTitle:
    'Specify whether SMS reminders for each appointment should always be sent.',
  sendSMSOnlyToFirstSessionOrAllDescription:
    'If you select "no," the SMS will only be sent before the first appointment in the series, and not for subsequent ones.',
  smsChangedTime: 'Send SMS when meeting time has changed',
  smsCancelledTime: 'Send SMS when meeting has been cancelled',
  buyMoreSms: 'Buy more SMS',
  smsInfo: 'Enable or disable automatic SMS notifications for patients',
  addSmsInfo:
    'Additionally, therapists will receive email notifications about new appointments and calendar changes',
  smsSendingInfo:
    'When shall your patients receive automated reminder message?',
  smsNewMeetingInfo:
    'Send an automated meeting reminder everytime a new patient has a new meeting scheduled. Those meetings will be send in the moment that meeting has been scheduled. ',
  smsTimeChangeInfo:
    'Your patients can reply to SMS messages to confirm or cancel appointments',
  smsTimeCancelledInfo:
    'Your patient will receive a new SMS everytime his meeting will be cancelled.',
  haveSms: 'You have ',
  smsLeft: 'messages left until ',
  smsAdditional: 'messages from additional packages',
  smsUsed: 'SMS to be used.',
  total: 'Total:',
  smsUsageInfo:
    'When you buy additional SMS they last beyond your billing cycle until they are used-up. In the first place the system uses the regular SMS, once there is no more it will start using the extra ones.',
  sendSmsTo: 'Send it',
  sendSmsBefore: 'before the meeting at',
  oClock: "o'clock",
  smsPrior: 'prior to the meeting',
  saveSms: 'Save',
  smsPeriodBefore: 'Certain time period before the meeting',
  smsSpecificTime: 'At a specific time prior to the meeting',
  dialogSmsInfo:
    'You will be billed {startPrice} zł + VAT({vat} zł) = {sum} zł which will be added to your next invoice.',
  smsInvoiceWarn:
    'Before proceeding with payment, please make sure you have entered correct practice invoice data. If it will be empty you will receive invoice for physical person, not for company. To see this data go to Settings -> Practice -> Invoice data',
  purchaseSmsExplanation:
    'After you click Buy an email will be sent to us and we will contact you with an invoice and payment details.',
  buy: 'Buy',
  cancel: 'Cancel',
  reminderMessage: 'Reminder Message',
  reScheduledMeeting: 'Re-scheduled meeting',
  confirmationMessage: 'Confirmation message',
  meetingCancelled: 'Meeting cancelled',
  quickReplies: 'Quick replies',
  reScheduledInfo:
    'This message will be sent when a planned meeting has been rescheduled. ',
  confirmationInfo:
    'This confirmation message will be sent to your patient once his first meeting will be scheduled.',
  canceledInfo:
    'This message will be sent to your patient when his meeting will be cancelled.',
  sendSpecificMessages: 'Send specific messages for each branch',
  selectBranch: 'Select branch',
  yourMessage: 'Message content',
  charts: 'characters',
  messageCount: '{n} message | {n} messages',
  restoreMessage: 'Restore default message',
  messageInfo:
    'Below are the parameters that you can use in your message content. Each parameter must be enclosed in curly brackets.',
  practice_name: 'will substitute with your practice short name',
  meeting_date: 'will substitute with the relevant meeting date',
  meeting_time: 'will substitute with relevant meeting time',
  meeting_room: 'will subscritie with relevant meeting room',
  practice_address: 'will substitue with relevant practice address',
  practice_phone: 'will substitute with your practice (or branch) phone number',
  therapists_phone_number:
    'will substitute with the relevant therapist phone number',
  branch_phone: 'will sustitute with relevant branch phone number',
  branch_address: 'will subscritite with relevant branch address',
  defaultSmsMessage: 'Default SMS message',
  restoreInfo:
    'The default text below cannot be changed but you can tailor it be adding or removing the practice address, phone and name. For each of those you can also add your own data. If you leave it blank it will be populated with the data of your practice.',
  customizeYourMessage: 'Customize your message',
  defaultText: 'Default text',
  searchPlaceholder: 'Search table',
  downloadCsv: 'Download CSV',
  moreFilters: 'Show advanced filters',
  recipient: 'Recipient',
  quota: 'Quota',
  date: 'Date',
  Message: 'Message',
  status: 'Status',
  smsUpdated: 'Sms config successfully updated',
  selectPracticeBranch: 'Select practice branch',
  replies: {
    header: 'Create new quick message',
    name: 'Message name',
    text: 'Message text',
    info: 'Quick messages can be a template if you are often sending the same text to your patients (e.g. payment reminders, holiday reminders, etc.)'
  },
  table: {
    name: 'Name',
    message: 'Message',
    addedOn: 'Added On',
    delete: 'Delete selected',
    empty: 'It seems no messages have been sent in the selected period.',
    emptyDesc: 'Choose a different period'
  },
  day1: '1 day',
  day2: '2 day',
  day3: '3 day',
  smsTemplatePerBranch: 'SMS Template per branch',
  smsTemplatePerBranchDescription:
    'Enable sms template per branch (cannot be disabled)',
  smsTemplatePerBranchTooltip: 'Already enabled, cannnot be disabled',
  restrictedAccess:
    'Invoice for using DobryGabinet has not been paid, smses are turned off',
  sendSMS: 'Send SMS',
  smsTextPlaceholder: 'Type your message ...',
  msgToPatient: 'Message to patient',
  phoneNumbers: 'Phone number(s):',
  maxSmsCountReached: 'You can send up to 10 SMS. Please shorten your message.',
  waitingConfirmation: 'Waiting for confirmation',
  smsSendingTimeWarning:
    'SMS messages are not sent at night. They will be sent tomorrow morning at 7 AM.',
  notifications: 'Notifications',
  smsSettings: 'SMS Settings',
  smsSettingsDescription:
    'Specify when and how patients will receive SMS notifications',
  emailSettings: 'Email Settings',
  emailSettingsDescription:
    'Specify when and how patients will receive email notifications',
  reminderMessageTitle: 'Specify SMS reminder content',
  confirmationMessageTitle: 'Specify SMS confirmation content',
  rescheduledMessageTitle: 'Specify SMS rescheduling content',
  cancelledMessageTitle: 'Specify SMS cancellation content',
  reminderInfo:
    'This message will be automatically sent to your patient before the scheduled appointment, according to your selected sending time settings.',
  sendTestTooltip:
    'Send yourself a test message to verify its correctness and see how it will appear to the patient.',
  restoreMessageTooltip:
    'Click to restore the default message content for this notification type.',
  guardian: 'GUARDIAN',
  patientGuardianTooltip: "SMS sent to patient's guardian"
}
