module.exports = {
  title: 'Practice settings',
  address: 'Practice address',
  contactDetails: 'Contact details',
  invoiceData: 'Invoice data',
  invoiceConfig: 'Invoicing config',
  name: 'Practice name',
  shortName: 'Short name',
  rooms: 'Rooms',
  exportData: 'Export data',
  formSteps: {
    name: 'What is your practice name?',
    address: 'What is your practice address?',
    phoneAndEmail: 'What is your practice phone and email address?'
  },
  select: {
    youAreViewing: 'You are viewing',
    clickToSwitch: 'Click to switch to',
    clickToSwitchPractice: 'Other offices (click to choose)',
    clickToAddNewPractice:
      'Click to add a new office according to the price list',
    addNewPractice: 'Add new Practice'
  },
  create: {
    buttons: {
      practiceAddress: 'Practice Address',
      contactDetails: 'Contact Details',
      nextStep: 'Next Step'
    }
  },
  logo: {
    image: 'Logo Image',
    uploadOrDrag: 'Upload or drag-and-drop a file'
  },
  branches: {
    all: 'All practice branches'
  },
  settings: {
    title: 'Settings',

    notesPerReception: 'Visiblity of notes for reception',
    notesPerReceptionDescription:
      "When this option is enabled, employees with role reception won't see notes for events",
    servicesPerTherapist: 'Visibility of services for therapists',
    servicesPerTherapistDescription:
      "When the visibility of services for therapists is enabled, therapists will only have access to the services they've been assigned to. Employees with admin or reception privileges will always have access to all services",
    onlyAdminReception:
      'Only Employees with Admin or Reception privileges can see this page',
    eventsPerTherapist: 'Dont show meetings of other therapists',
    eventsPerTherapistDescription:
      'After enabling this option, the therapist will only see his or her own meetings in the calendar, without seeing the meetings of other patients. Employees with admin or reception privileges will always have access to all events',

    calendarVisibility: 'Calendar visibility',
    employeeDataVisibility: 'Employee data visibility',

    hidePersonalEmployeeData: "Hide personal data in the 'Employees' table",
    hidePersonalEmployeeDataDescription:
      "After enabling this option, employees will not have access to the personal data of other employees in the 'Employees' table. They will only see basic information, such as name and surname (if applicable)."
  },
  invoice: {
    taxNo: '@:invoices.sidebar.preview.taxNo',
    bankAccount: '@:invoices.sidebar.preview.bankAccount',
    bank: '@:invoices.sidebar.preview.bank',
    save: '@:common.save',
    companyName: '@:invoices.sidebar.preview.companyName',
    companyStreet: 'Company street',
    address: '@:invoices.sidebar.preview.address',
    postCode: 'Post code',
    city: 'City',
    country: 'Country',
    apiKey: 'API key',
    contractor: 'Contractor',
    privatePerson: 'Private person',
    invoicesActive: 'Invoices active',
    invoicesInactive: 'Invoices inactive',
    apiToken: 'API key',
    apiTokenDescription: 'You can find the API key in Fakturownia. ',
    subDomainDescriptions: {
      part1: 'For example, for the address ',
      part2: 'https://',
      part3: 'your-subpage.',
      part4: 'fakturownia.pl',
      part5: 'enter ',
      part6: 'your-subpage'
    },
    clickToFind: 'Click here to find out how to find him.',
    subdomain: 'Subdomain',
    deleteLinkWithFakturownia: 'Remove connection with Fakturownia',
    fullName: 'Full name',
    email: 'Email'
  },
  exportDataOptions: {
    exportMeetings: {
      title: 'Export meeting data',
      description:
        'Click here to download complete data on all meetings. You can also select a date range for the meetings you are interested in.',
      selectRange: 'Select date range',
      dateRangePrompt:
        'Select a date range or leave the field empty to download all meetings.',
      btnLabel: 'Download meetings'
    },
    exportPatients: {
      title: 'Export Patient Data',
      description: 'Click here to download complete data on all patients.',
      btnLabel: 'Download Patients'
    },
    exportTherapists: {
      title: 'Export Therapist Data',
      description: 'Click here to download complete data on therapists.',
      btnLabel: 'Download Therapists'
    }
  }
}
